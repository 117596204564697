import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { message } from "antd";
import LogoutIcon from "@mui/icons-material/Logout";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./SideMenu.css";

const SideMenu = ({ sideMenu, setSideMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const [submenu, setSubmenu] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    message.success("Logout Successful");
    navigate("/login");
  };
  return (
    <div
      className={`sidemenu-container d-block d-md-block d-lg-none ${
        sideMenu ? "active" : ""
      }`}
    >
      <div className="sidemenu">
        <HighlightOffIcon
          onClick={() => setSideMenu(!sideMenu)}
          className="close-icon"
        />
        <ul className="p-0">
          {user?.isAdmin && (
            <li
              className={`${
                location.pathname === "/admin-dashboard" && "active"
              }`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <Link to="/admin-dashboard">Admin Dashboard</Link>
            </li>
          )}
          <li
            className={`${location.pathname === "/" && "active"}`}
            onClick={() => setSideMenu(!sideMenu)}
          >
            <Link to="/">Home</Link>
          </li>
          {user && (
            <li
              className={`${
                location.pathname === "/user-dashboard" && "active"
              }`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <Link to="/user-dashboard">Dashboard</Link>
            </li>
          )}
          {user && (
            <li
              className={`${location.pathname === "/my-account" && "active"}`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <Link to="/my-account">Account</Link>
            </li>
          )}
          {user && (
            <li
              className={`${location.pathname === "/orders" && "active"}`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <Link to="/orders">Orders</Link>
            </li>
          )}
          {user && (
            <li
              className={`${location.pathname === "/wallet" && "active"}`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <Link to="/wallet">Wallet</Link>
            </li>
          )}

          <div className="wa-icon-cont mb-3" style={{ position: "static" }}>
            <Link target="_blank" to="https://wa.me/918638612227">
              <WhatsAppIcon className="icon" />
              <span> Support</span>
            </Link>
          </div>

          {!user && (
            <div
              className="sidemenu-action-btn"
              onClick={() => setSideMenu(!sideMenu)}
            >
              <Link to="/login">Login</Link>
            </div>
          )}
          {user && (
            <div className="logout" onClick={handleLogout}>
              Logout
              <LogoutIcon className="icon" />
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
