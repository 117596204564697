import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import HowItWorks from "../components/Home/HowItWorks";
import SliderText from "../components/Home/SliderText";
import Products from "../components/Products";
import Features from "../components/Features.js";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import "./Home.css";

const Home = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [image, setImage] = useState("");
  const [popImage, setPopImage] = useState("");
  const [imageSelect, setImageSelect] = useState("");
  const [link, setLink] = useState("");
  const [display, setDisplay] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [isFixed, setIsFixed] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [text, setText] = useState("");
  const [notification, setNotification] = useState(false);

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
        } else {
          localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  async function getNoti() {
    try {
      const res = await axios.get("/api/noti/get-noti");
      if (res.data.success) {
        setImage(res.data.data[0].image);
        setPopImage(res.data.data[0].popImage);
        setImageSelect(res.data.data[0].imageSelect);
        setLink(res.data.data[0].link);
        setDisplay(res.data.data[0].display);
        setButtonText(res.data.data[0].buttonText);
        setIsFixed(res.data.data[0].isFixed);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function getSlideText() {
    try {
      const res = await axios.get("/api/banner/get-slide-text");
      if (res.data.success) {
        setText(res.data.data.text);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleClosePopup = () => {
    setShowPopup(false);
    localStorage.setItem("giveaway", "false");
  };

  useEffect(() => {
    getUserData();
    getNoti();
    getSlideText();

    setTimeout(() => {
      setShowPopup(true);
    }, 1000);

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want quit?";
      localStorage.setItem("giveaway", "true");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Layout>
      <div className="main-home-container">
        {showPopup && display === "yes" && (
          <div
            className="popup-container"
            onClick={() => isFixed === "no" && setShowPopup(false)}
          >
            <div>
              {isFixed === "no" && (
                <CancelIcon onClick={handleClosePopup} className="icon" />
              )}
            </div>
            <Link target="_blank" to={link}>
              {imageSelect === "one" ? (
                <img src={`/${image}`} alt="popup-img" />
              ) : (
                <img src={`/${popImage}`} alt="popup-img" />
              )}
              <button onClick={() => window.open(link)}>{buttonText}</button>
            </Link>
          </div>
        )}
        <HeroSection />
        <Products title={"Trending Games"} />
      </div>
    </Layout>
  );
};

export default Home;
