import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import website from "../website/data";
import CancelIcon from "@mui/icons-material/Cancel";
import "./Products.css";
import "../pages/Home.css";

const Games = ({}) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(null);
  const [notification, setNotification] = useState(false);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  function checkUser(name) {
    if (user && user.user) {
      navigate(`/product/${name}`);
    } else {
      setNotification(true);
    }
  }

  const renderCategory = (category) => {
    const filteredProducts = products?.filter(
      (item) => item.category === category
    );
    if (filteredProducts && filteredProducts.length > 0) {
      return (
        <div className="popular-games">
          <div className="titlee">
            <h5>{category}</h5>
            <span onClick={() => navigate("/games")}>View More</span>
          </div>
          <div className="games-list">
            {filteredProducts
              ?.filter((item) => !item?.hide)
              .map((item, index) => (
                <div
                  key={index}
                  className="game"
                  onClick={() => checkUser(item?.name)}
                >
                  <img src={`${website.link}/${item?.image}`} alt="pro-img" />
                  <h5 className="m-0">{item?.name}</h5>
                </div>
              ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="explore-products-container">
      {renderCategory("Popular")}
      {renderCategory("Mobile Legends")}
      {renderCategory("Recommended Packs For Event")}
      {renderCategory("Discounted Packs")}
      {renderCategory("Event Packs")}
      {renderCategory("Other")}

      {notification && (
        <div className="loginpopup">
          <div className="loginpopup-content">
            <div className="text-end mb-3">
              <CancelIcon
                className="icon"
                onClick={() => setNotification(!notification)}
              />
            </div>
            <div className="heading">
              Kindly Login first to see the product list
            </div>
            <div className="buttons">
              <button onClick={() => navigate("/login")}>Login</button>
              <button onClick={() => navigate("/register")}>
                Create Account
              </button>
            </div>
            <div className="text-end mt-2">
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Games;
